<template>
  <div :class="isValidToken ? 'mt-0 p-2' : 'mt-12 p-2'">
     <div class=" bg-white rounded-xl" v-if="!isValidToken">
        <div class="relative">
          <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
            <div class=" relative flex items-center justify-between">
              Invoice ({{totalInvoice}})
            </div>
          </div>
        </div>
        <div class="pt-1">
          <div class="mt-4 p-2 pb-0">
            <div class="flex flex-wrap justify-between items-start">
              <div class="w-64 mb-4" :class="mobileView ? 'w-64' : 'w-72'">
                <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Invoice...`"/>
              </div>
              <div>
                <span @click="filterShow = true" :class="`cursor-pointer text-3xl ${filterApply ? 'text-success' : 'text-primary'}`"><i class="fa-solid fa-filter"></i></span>
              </div>
            </div>
          </div>
          <div>
            <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="!mobileView">
              <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
                <div class="table_col w-12 uppercase  flex items-center" :class="titleObj[0].icon !== '' ? 'text-primary' : ''" >
                  <!-- <span class="cursor-pointer">{{titleObj[0].title}}</span> -->
                  <span class="cursor-pointer" @click="sortByColumn(0)">#</span>
                   <div v-if="titleObj[0].icon !== ''">
                      <descIconSvg v-if="titleObj[0].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                      <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                    </div>
                </div>
                <div class="table_col w-52 uppercase  flex items-center" :class="titleObj[1].icon !== '' ? 'text-primary' : ''">
                  <span class="cursor-pointer" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
                   <div v-if="titleObj[1].icon !== ''">
                      <descIconSvg v-if="titleObj[1].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                      <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                    </div>
                </div>
                <div class="table_col w-52 uppercase  flex items-center" :class="titleObj[2].icon !== '' ? 'text-primary' : ''">
                  <span class="cursor-pointer" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
                   <div v-if="titleObj[2].icon !== ''">
                      <descIconSvg v-if="titleObj[2].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                      <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                    </div>
                </div>
                <div class="table_col w-44 uppercase  flex items-center" :class="titleObj[3].icon !== '' ? 'text-primary' : ''">
                  <span class="cursor-pointer" @click="sortByColumn(3)">{{titleObj[3].title}}</span>
                   <div v-if="titleObj[3].icon !== ''">
                      <descIconSvg v-if="titleObj[3].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                      <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                    </div>
                </div>
                <div class="table_col w-44 uppercase  flex items-center" :class="titleObj[4].icon !== '' ? 'text-primary' : ''">
                  <span class="cursor-pointer" @click="sortByColumn(4)">{{titleObj[4].title}}</span>
                   <div v-if="titleObj[4].icon !== ''">
                    <descIconSvg v-if="titleObj[4].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                    <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                  </div>
                </div>
              </div>
              <div v-if="invoiceList.length > 0">
                  <div v-for="(data, index) in invoiceList" :key="index"  class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="openInvoiceDetails(data)">
                    <div class="table_col w-12 ">
                      <div class="">
                      <p class="  text-gray4 heading-6 "># {{data.invoiceNumber}}</p>
                      </div>
                    </div>
                    <div class="table_col w-52 ">
                      <div class="flex items-center">
                      <span class="  text-gray4 heading-6">{{data.invoiceDate | formatForDatePicker}}</span>
                      </div>
                    </div>
                    <div class="table_col w-52">
                      <div class="flex items-center">
                      <span class="  text-gray4 heading-6">{{data.dueDate | formatForDatePicker}}</span>
                      </div>
                    </div>
                    <div class="table_col w-44 ">
                      <div class="flex items-center">
                      <span class="  text-gray4 heading-6">{{data.grandTotal | amountOnlyNumber}}</span>
                      </div>
                    </div>
                    <div class="table_col w-44 ">
                      <div class="flex items-center">
                      <span :style="`color: ${data.invoiceStatusColor};`" class=" text-gray4 heading-6">{{data.invoiceStatusName}}</span>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="invoiceList.length > 0 && mobileView">
            <div v-for="(data, index) in invoiceList" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="showJobDetail(data)">
              <div class="">
                <!-- <p class="  text-text1 font-bold">{{}}</p> -->
                <div class="flex justify-between items-center">
                  <p class="text-gray4 heading-5">#{{data.invoiceNumber}}</p>
                  <div class="flex items-center">
                    <span :style="`color: ${data.invoiceStatusColor};`" class=" text-gray4 heading-6">{{data.invoiceStatusName}}</span>
                  </div>
                </div>
                <div class="flex items-center">
                  <p class="text-gray4 heading-6 w-24">Invoice Date: </p>
                  <span class="text-gray4 heading-5">{{data.invoiceDate | formatForDatePicker}}</span>
                </div>
                <div class="flex items-center">
                  <p class="text-gray4 heading-6 w-24">Due Date: </p>
                  <span class="text-gray4 heading-5">{{data.dueDate | formatForDatePicker}}</span>
                </div>
                <div class="flex items-center">
                  <p class="text-gray4 heading-6 w-24">Total: </p>
                  <span class="text-gray4 heading-5">{{data.grandTotal | amountOnlyNumber}}</span>
                </div>
              </div>
            </div>
          </div>
            <div v-if="invoiceList.length === 0">
              <NoRecords :alertMessage="`No records found`" />
            </div>
          </div>
            <div>
              <Pagination
                :totalDataCount="totalInvoice"
                :resetPagination="resetPaginationValues"
                @callApiAsPerPagination="getDataFromPagination"/>
          </div>
        </div>
    </div>
    <div v-if="isValidToken" class="popup_overlay">
        <div class="bg-gray-200 w-full px-2 md:px-0 h-screen flex items-center justify-center">
          <div class="bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-20 py-8 rounded-lg shadow-2xl">
              <p class="text-6xl md:text-7xl lg:text-6xl font-bold tracking-wider text-gray-300"><i class="fa-solid fa-triangle-exclamation fa-fade"></i></p>
              <p class="text-gray-500 mt-3 py-2 border-y-2 text-center">Please Check Your Link and Try Again Later</p>
          </div>
        </div>
      </div>
     <div v-if="isExpired">
        <EmailVarified />
     </div>
    <InvoiceFilterDrawer @applyFilters="applyFilters" :navigationDialog="filterShow"/>
  </div>
</template>
<script>
import SearchTextInput from '@/View/components/searchTextInput.vue'
import MyJobApp from "@/api/MyJobApp.js";
import NoRecords from '@/View/components/noRecords.vue'
import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
import ascIconSvg from '@/assets/images/svg/ascIconSvg.vue'
import descIconSvg from '@/assets/images/svg/descIconSvg.vue'
import InvoiceFilterDrawer from '@/View/invoice/components/InvoiceFilterDrawer.vue';
import EmailVarified from '@/View/endUserNewFlow/components/emailVarified.vue'
export default {
components: {
  ascIconSvg,
  NoRecords,
  descIconSvg,
  Pagination,
  SearchTextInput,
  InvoiceFilterDrawer,
  EmailVarified
},
props: [],
mixins: [deboucneMixin],
data () {
  return {
      mobileView: false,
      isValidToken: false,
      isExpired: false,
      ShowErr: false,
      sessionToken: '',
      totalInvoice: 0,
      resetPaginationValues: false,
      statusList: [
        { statusId: 1, statusName: 'Open', selected: true},
        { statusId: 2, statusName: 'Overdue', selected: true},
        { statusId: 3, statusName: 'Partially Paid', selected: true},
        { statusId: 4, statusName: 'Paid', selected: true},
      ],
      filterObject: {
        start: 0,
        length: 20,
        sortOrder: 'DESC',
        searchVal: '',
        isActive: true,
        sortBy: 'invoiceDate',
        statusList: [],
        from: '',
        to: '',
      },
      invoiceList: [],
      titleObj: [
        {title: 'Invoice Number', icon: '', sortName: 'invoiceNumber'},
        {title: 'Invoice Date', icon: '', sortName: 'invoiceDate'},
        {title: 'Due Date', icon: 'DESC', sortName: 'dueDate'},
        {title: 'Toal Amount', icon: '', sortName: 'grandTotal'},
        {title: 'Status', icon: '', sortName: 'invoiceStatusName'},
      ],
        filterShow: false,
        filterApply: false,
  }
},
created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
mounted () {
  this.resizeWindowHandler()
  document.title = 'EndUser: Invoice'
  this.sessionToken = this.$route.params.customerToken
   this.getListDeboucne = this.debounce(function () {
    this.resetPaginationValues = true
  }, 500)
  this.$root.$on('tokenResponse', (isTokenExpired, isTokenInvalid) => {
      if (!isTokenExpired && !isTokenInvalid) {
        this.getInvoicesList()
      } else if (isTokenExpired && !isTokenInvalid) {
        this.isExpired = true
        this.isValidToken = false
        this.firstLoading = true
      } else if (!isTokenExpired && isTokenInvalid) {
        this.isExpired = false
        this.isValidToken = true
        this.firstLoading = true
      }
    })
    this.$root.$emit('tokenVarificationApi', this.$route.params.customerToken)
},
beforeDestroy() {
    this.$root.$off('tokenResponse')
  },
watch: {
},
methods: {
  applyFilters (data) {
    console.log(data);
    if (data === null) {
      this.filterObject.from = ''
      this.filterObject.to = ''
      this.filterApply = false
      this.getInvoicesList()
    } else if (!data) {
      this.filterShow = false
      return
    } else {
      this.filterApply = true
      this.filterObject.from = data.startDate
      this.filterObject.to = data.endDate
      this.getInvoicesList()
    }
    this.filterShow = false
  },
  getDataFromPagination (data) {
    this.filterObject.start = data.offset
    this.filterObject.length = data.limit
    
    this.getInvoicesList(true)
  },
  sortByColumn (index) {
    console.log(index);
    this.titleObj.forEach((el, i) => {
      if (i === index) {
        if (el.icon === '') {
          el.icon = 'DESC'
          this.filterObject.sortOrder = 'DESC'
        } else if (el.icon === 'DESC') {
          el.icon = 'ASC'
          this.filterObject.sortOrder = 'ASC'
        } else {
          el.icon = 'DESC'
          this.filterObject.sortOrder = 'DESC'
        }
      } else {
        el.icon = ''
      }
    })
    this.filterObject.sortBy = this.titleObj[index].sortName
    this.getInvoicesList(true)
  },
  searchCall (data) {
    this.filterObject.searchVal = data
    this.getListDeboucne()
  },
  openInvoiceDetails (data) {
    this.$router.push({name: 'EndUserInvoiceDetail', params: {customerToken: this.sessionToken, invoiceToken: data.invoiceLinkHash}})
  },
  getInvoicesList() {
    let isValid = true
    this.filterObject.statusList = []
    this.statusList.map((data) => {
      if (data.selected) {
        this.filterObject.statusList.push(data.statusId)
      }
    })
    if (this.filterObject.statusList.length === 0) {
      this.invoiceList = []
      isValid = false 
    }
    console.log("call getInvoicesList", this.$route);
    if (isValid) {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.GetEndUserInvoiceList(
        this.sessionToken,
        this.filterObject.start,
        this.filterObject.length,
        this.filterObject.searchVal,
        this.filterObject.sortBy,
        this.filterObject.sortOrder,
        '',
        this.filterApply ? new Date(this.filterObject.from).toUTCString() : '',
        this.filterApply ? new Date(this.filterObject.to).toUTCString() : '',
        (response) => {
          this.resetPaginationValues = false
          this.invoiceList = response.Data.tableRow !== null ? response.Data.tableRow  : []
          this.totalInvoice = response.Data.count
          console.log("response getInvoicesList", response);
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          if (err.message === 'Your session has expired') {
            this.ShowErr = true
          }
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.resetPaginationValues = false
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    }
  },
  resizeWindowHandler() {
      if (window.innerWidth < 800) {
        this.mobileView = true
      } else {
        this.mobileView = false
      }
    },
}
}
</script>
<style scoped>
.table_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
