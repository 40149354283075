<template>
  <div>
     <div class="popup_overlay relative px-4">
        <div style="width: 490px;" class="custom_dialog rounded-xl h-min">
          <div v-if="emailSelection" class="bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 py-8 rounded-lg shadow-2xl">
            <p class="text-6xl md:text-7xl lg:text-6xl font-bold tracking-wider text-gray-300"><i class="fa-solid fa-triangle-exclamation fa-fade"></i></p>
            <p class="text-gray-500 my-3 py-2 border-y-2 text-center">Your session token has expired, Re-verify your email</p>
              <div class="mb-4 w-full">
                   <TextField
                    :inputId="`useremailid1`"
                    :inputext="userEmail"
                    :placholderText="`Email Address`"
                    :lableText="'Email Address'"
                    :fieldError="emailErr !== ''"
                    :autoFocus="true"
                    :inputType="'text'"
                    @keyPressAction="emailErr = ''"
                    @enterkeyPressAction="openPlanDetail"
                    @blurAction="emailHandler(userEmail, '')"
                    @inputChangeAction="(data) => userEmail = data"  />
                    
                    <p class="text-error"> {{ emailErr }}</p>
                </div>
            <div class="flex justify-center mt-5">
              <button @click="openPlanDetail()" class="card bg-primary text-white px-5 py-3 text-center rounded-lg hover:opacity-80 w-96 font-semibold">Send Email</button>
            </div>
          </div>
          <div v-else class="border border-gray-200 px-4 md:px-8 py-8 rounded-lg shadow-2xl">
                    <p class="">Please enter OTP sent to <span class="font-semibold">{{userEmail}}</span></p>
                    <div class=" my-4 flex justify-center">
                        <div class="w-56">
                        <TextField
                        :isOtp="true"
                        :fieldError="otpErr !== ''"
                        @keyPressAction="otpErr = ''"
                        :inputId="`otpinputvalueid`"
                        :inputext="otpValue"
                        :placholderText="``"
                        :lableText="''"
                        :autoFocus="true"
                        :inputType="'text'"
                        @inputChangeAction="(data) => otpValue = data"  />
                        </div>
                    </div>
                    <div class="flex justify-between mb-4">
                        <div class=" cursor-pointer text-primary" @click="emailSelection = true">Change Email</div>
                        <div v-if="countDown > 0" class="">Resend OTP  <span >in {{countDown}} Sec</span></div>
                        <div v-else @click="reSendOtp" class=" cursor-pointer text-primary">Resend OTP</div>
                    </div>
                    <div class="py-2">
                    <Button  :disabled="otpValue ===''" class=" h-10 w-full" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Verify OTP'" @buttonAction="verifyOtp()"/>
                  </div>
                </div>
        </div>
      </div>
   </div>
</template>
<script>
import MyJobApp from '@/api/MyJobApp.js'
import Button from '@/View/components/globalButton.vue'
import TextField from '@/View/components/textfield.vue'
import {validateEmail} from '@/utils/validation.js'
export default {
  name: "lead",
  components: {
    Button,
    TextField
  },
  data() {
    return {
      Otptoken: '',
      emailSelection: true,
      countDown: 30,
      otpErr: '',
      otpValue: '',
      userEmail: '',
      emailErr: '',
      sessionToken: '',
      emailValid: false,
    };
  },
  created() {},
  mounted() {
    this.$root.$on('changeEmailHandler', (response, data) => {
        if (response) {
            this.userEmail = data.emailAddress
            this.countDown = 30
            this.countDownTimer()
        }
        this.emailSelection = false
    })
    this.sessionToken = this.$route.params.customerToken
    // this.getVisitList()
  },
  destroyed () {
    this.$root.$off('changeEmailHandler')
  },
  beforeDestroy() {
  },
  methods: {
    countDownTimer () {
        if (this.countDown > 0) {
            setTimeout(() => {
                this.countDown -= 1
                this.countDownTimer()
            }, 1000)
        }
    },
    openPlanDetail () {
      this.emailHandler(this.userEmail, '')
      if (this.userEmail === '') {
        this.emailErr = 'Email address is required'
      }
      if (this.emailErr === '') {
        this.$store.dispatch("SetLoader", { status: true, message: "Email sending..." });
        MyJobApp.SendEndUserOtp(
        this.sessionToken,
        this.userEmail,
        response => {
          this.Otptoken = response.Data
          this.countDownTimer()
          this.emailSelection = false
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      )
      }
    },
    reSendOtp () {
      this.$store.dispatch("SetLoader", { status: true, message: "Resending..." });
        MyJobApp.reSendEndUserOtp(
        this.Otptoken,
        response => {
          this.countDown = 30
          this.countDownTimer()
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      )
    },
    verifyOtp () {
        MyJobApp.VarifyAddedOTPEndUSer(
          this.sessionToken,
          this.Otptoken,
          this.otpValue,
          (response) => {
            console.log('response', response)
            location.reload()
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: 'OTP verified successfully', color: 'success'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        ) 
    },
    emailHandler (emailId, from) {
      if (from === '') {
        this.emailErr = this.emailValidation(emailId)
        if (this.emailErr === 'Email address is invalid') {
          this.emailValid = false
        } else {
          this.emailValid = true
        }
      } else if (from === 'forgot') {
        this.forgotEmailErr = this.emailValidation(emailId)
      }
    },
    emailValidation (emailId) {
      if (emailId === '') {
        return ''
      } else if (validateEmail(emailId) === false) {
        return 'Email address is invalid'
      } else {
        return ''
      }
    },
  }
};
</script>
<style scoped>
</style>